/* allow IE to peacefully ignore console statements  */	
if (!window.console) console = {};
console.log = console.log || function(){};
console.warn = console.warn || function(){};
console.error = console.error || function(){};
console.info = console.info || function(){};

/*
		Sets the height of a container so the footer is pushed to the bottom of the window.
*/
jQuery.fn.hugBottom = function(options) {
	if($(this).length > 1)
	{
		console.warn("Only one footer is supported.")
		return $(this);
	}

	if($(this).length === 0)
	{
		//console.error("Selector Not found: " + (this.selector || 'cannot discover selector with jQuery ' + $.fn.jquery));	
		return $(this);
	}
  
	// here we go:
	var footer = $(this)
	var data = footer.data()

  // heros are things that are rendered edge-to-edge outside of .content
	var default_options = {
		content: $('.content'),
		header: $('header'),
    hero: $('.main-hero'),
	}
	data.options = $.extend(default_options, options);
  
  var content = data.options.content;
  var header = data.options.header;
  var hero = data.options.hero;

  if(footer.data('initialized')==undefined){
    footer.data('initialized',true);
    $(document).on('page:load ready', function(){
      updateContentHeight();
    });

    $(window).resize(function(){
      updateContentHeight();
      if(data.options.callback) {
      	data.options.callback();
      }
    });

    $(document).on("turbolinks:load",function(){
    	//TODO: unbind all the existing event listeners

    	// Re-initialize with updated elements after turbolinks load
			$(footer.selector).hugBottom( { content: $(data.options.content.selector), header: $(data.options.header.selector), hero: $(data.options.hero.selector) });
		});

  }

	function updateContentHeight() {
		// You can set visibility to 'hidden' in css if you don't want the footer to jump.
    footer.css('visibility','visible');
    var minHeight = $(window).height() - footer.outerHeight() - (footer.css('margin-top').replace('px','')/1);
    if (header.css('position') != 'absolute') {
      minHeight -= header.outerHeight();  
    }
    
    //console.log(content.length)
    //minHeight -= $('#nav1').outerHeight();
    if(content.length > 0){
      minHeight -= (content.css('margin-top').replace('px','')/1);
    }
    if(hero.length > 0){
      minHeight -= hero.outerHeight(); 
    }
    //console.log('minHeight: ' + minHeight)
    content.css('min-height', minHeight + 'px');
  }

	updateContentHeight();

	return $(this);
}
