PaceLookup = {
  init: function() {
    if ($(".pace-panel").length > 0) {
      $("#pace_section").change(function() {
        $(".pace-panel").hide();
        $("#" + $(this).val()).show();
      });
      $("#pace_section").trigger("change");
    }
  }
}

$(document).on('turbolinks:load', PaceLookup.init);