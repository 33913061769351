LIBRARY_MESSAGE_CREATOR = {
	init: function() {
		var body = $("#library_message_body");
	  var counter     = $("#counter");
	  var max_length  = counter.data("maximum-length");

	  body.keyup(function() {
	      counter.text($(this).val().length);
	      if(max_length - $(this).val().length < 0) {
	        //$('form input[type=submit]').attr('disabled','disabled');
	        counter.addClass('warn');
	      } else {
	        //$('form input[type=submit]').removeAttr('disabled');
	        counter.removeClass('warn');
	      }

	  });

	}
	//TODO: Detect invalid placeholders? (enforce <> for user-generated placeholders)
}

document.addEventListener("turbolinks:load", function() {
  if($("#library_message_body").length > 0) {
    LIBRARY_MESSAGE_CREATOR.init();
  }
});