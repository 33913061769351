REPORT_REQUESTS = {
	init: function() {
		$('#report_request_timeline').datepicker('option', 'minDate', 0);

		if($("#report_request_report_type").length > 0){
			$('.field:not(.inital-prompt)').hide();

			$("#report_request_report_type").change(function(){
				var t = $("#report_request_report_type").val().toLowerCase().replace(/\s/g,"-");
				$('.field:not(.global)').hide();
				if (t.length > 0){
					$('.field.global,.field.' + t).show();	
				}
				
			}).change();

			REPORT_REQUESTS.setupOtherField("#report_request_permission", "#other_permission");
			REPORT_REQUESTS.setupOtherField("#report_request_sort_by", "#other_sort_by");
			
		}
		
	},
	setupOtherField: function(select_selector, other_selector) {
		$(select_selector).change(function() {
			if($(this).val() == "Other") {
				$(other_selector).show();
			} else {
				$(other_selector).hide();
			}
		}).change()
	}
}

$(document).on('turbolinks:load', REPORT_REQUESTS.init);