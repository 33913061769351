


var QuickSight = {
	onDashboardLoad: function(payload) {
		clearTimeout(QuickSight.dashboardLoadFallback)
		$("#qs_loading").hide()
		$(".report-wont-load").hide()
		//console.log('hey there you cool baby.')
		//QuickSight.resizeReport()
		
		const params = new Proxy(new URLSearchParams(window.location.search), {
		  get: (searchParams, prop) => searchParams.get(prop),
		});

		if(params.sheet_id){
			//console.log(`Sheet ID: ${params.sheet_id}`)
			setTimeout(function() {
				//console.log('Switching now!')
				dashboard.setSelectedSheetId(params.sheet_id)
			}, 100)
		} else {
			//console.log('No Sheet ID supplied.')
		}

		// report this sheet as a report viewing
		$.post(window.location.pathname + '/report_views', {} )
	},
	onError: function(payload) {
		$("#qs_loading").hide()

		console.log("Dashboard failed to load");
		console.log(payload)
	},
	onSheetChange: function(payload) {
		//console.log("Sheet Change!")
		//console.log(payload)
		// {selectedSheet: {name: "Highest Risk", sheetId: "da13fdd3-24c3-4ec3-aead-47dda3524feb_a5f92ba9-3e4f-4ae5-8f75-6b707798a96c"}}
		var sheet = payload.selectedSheet	
		// report this sheet as a report viewing
		$.post(window.location.pathname + '/report_views', { sheet_name: sheet.name, sheet_id: sheet.sheetId })
	},
	embedDashboard: function() {
		if($('#qs_dashboard').length === 0) { return }
		
		$('#qs_dashboard').empty();

		var parameters = {}; //{ "StartDate": '2020-06-01' }
		
		var dashboard = QuickSightEmbedding.embedDashboard({
			url: $('#qs_dashboard').data('url'),
			container: '#qs_dashboard',
			scrolling: "yes",
			parameters: parameters,
			//selectedSheetChangeCallback: function(p) { console.log('hey'); console.log(p); } 
		});

		QuickSight.dashboardLoadFallback = setTimeout(QuickSight.onDashboardLoad, 5000)

		dashboard.on("error", QuickSight.onError);
		dashboard.on("load", QuickSight.onDashboardLoad);
		dashboard.on("selectedSheetChange", QuickSight.onSheetChange);
		
		window.dashboard = dashboard;

	}
}

$(document).on('turbolinks:load', QuickSight.embedDashboard);
