ADMISSION_EPISODES = {
	init: function() {
		$(document).on('change',"#missed_and_missing", function(e) {
			// this syncronizes the hidden checkbox in the form to the visible checkbox in the tab:
			$("#filters_list_missed_and_missing").prop('checked',$(this).is(":checked"));
			ADMISSION_EPISODES.submitForm($("#filters_list_missed_and_missing"));
		});
		$(document).on('change',"#total", function(e) {
			// this syncronizes the hidden checkbox in the form to the visible checkbox in the tab:
			$("#filters_list_total").prop('checked',$(this).is(":checked"));
			ADMISSION_EPISODES.submitForm($("#filters_list_total"));
		});
	},
	submitForm: function(element) {
		var form = $(element).parents('form')[0];
    global.Rails.fire(form,'submit')
	}
/*
		// named lists were obviated by moving to tabbed design but it's possible
		// they make a comeback some day with new list ideas
	initNamedLists: function() {
		$("#filters_list_recently_discharged").change(function(e) {
			if(!$(this).is(":checked")) {
				$("#filters_list_missed_and_missing").prop("checked", false)
			}
			ADMISSION_EPISODES.submitForm(this);
		});

		$("#filters_list_missed_and_missing").change(function(e) {
			if($(this).is(":checked")) {
				$("#filters_list_recently_discharged").prop("checked", true)
			}
			ADMISSION_EPISODES.submitForm(this);
		});

		$("#filters_list_recently_closed").change(function(e) {
			if($(this).is(":checked")) {
				$("#filters_list_recently_discharged").prop("checked", false)
				$("#filters_list_active").prop("checked", false)
				$("#filters_list_missed_and_missing").prop("checked", false)
			}
			ADMISSION_EPISODES.submitForm(this);
		});

		$("#filters_list_active").change(function(e) {
			ADMISSION_EPISODES.submitForm(this);
		});
	},
*/
}

document.addEventListener("turbolinks:load", function() {
  if($("#missed_and_missing").length > 0) {
    ADMISSION_EPISODES.init();
  }

});