SITE_ORIENTATIONS = {
	init: function() {
        SITE_ORIENTATIONS.disableFormIfNeeded($('form.js-all-checkboxes-required'))
		$(document).on('change', 'form.js-all-checkboxes-required', function(e){
            SITE_ORIENTATIONS.disableFormIfNeeded($(this))
			return false;
		});
	},
    disableFormIfNeeded: function(form) {
        var form = $(form)[0];
       // console.log("Something Changed!");
        var allBoxesAreChecked = true;
        $(form).find("input[type=checkbox]").each(function(i,checkbox){
            //console.log($(checkbox).prop("checked"))
            if(!$(checkbox).prop("checked")){
                allBoxesAreChecked = false;
                return
            }
        });
        if(allBoxesAreChecked){
            //console.log("Enable submit button")
            $(form).find("input[type=submit]").prop("disabled",false).removeClass("disabled")
        } else {
            //console.log("disable submit button")
            $(form).find("input[type=submit]").prop("disabled",true).addClass("disabled")
        }
    }
}

$(document).on('turbolinks:load', SITE_ORIENTATIONS.init)