import $ from 'jquery'
import 'select2'

function formatResult (result) {
	if (result.loading) {
		return result.full_name;
	}

	var $container = $(`
		<div class="grid">
			<div class="grid__item medium-up--one-half">
				<h5 class="member-name">${result.full_name}</h5>
				<div class="meta-data">
					<div><span class="person-number">${result.job_title}</span></div>
				</div>
			</div>
			<div class="grid__item medium-up--one-half">
				<div class="meta-data">
					<div><span class="person-number">${result.email}</span></div>
					<div><span class="person-number">${result.department}</span></div>
					<div><span class="person-number">${result.status}</span></div>
				</div>
			</div>
		</div>`
		);

	return $container;
}

function formatSelection(selection) {
	return selection.text || selection.full_name;
}

function initializeSelects() {
	$('.js-employee-search').each(function(index, elem) {
		var data_scope = $(elem).data('scope');
		var prompt = $(elem).data('placeholder') || 'Search for Employee...';
		$(elem).select2({
			placeholder: prompt,
			ajax: {
				url: '/employees',
				dataType: 'json',
				width: 'style',
				data: function (params) {
					var query = {
						q: params.term,
						scope: data_scope,
						page: params.page || 1,
						per_page: 100
					}

					// Query parameters will be ?q=[term]&page=[page]
					return query;
				},
				processResults: function (data, params) {
					// parse the results into the format expected by Select2
					// since we are using custom formatting functions we do not need to
					// alter the remote JSON data, except to indicate that infinite
					// scrolling can be used
					params.page = params.page || 1;

					return {
						results: data.items,
						pagination: {
							more: (params.page * 30) < data.pagination.count
						}
					};
				},
				cache: true
			},
			templateResult: formatResult,
			templateSelection: formatSelection
		})
	});

	$(".js-employee-detail_ids").each(function(index, element){
		// this is a list of ids
		//$(element).val()
	});

	$(".js-employee-detail-name").each(function(index, element){
		setTimeout(function() {
			$(element).siblings('.select2-container--default').find('.select2-selection__rendered').removeAttr('title');
			$(element).siblings('.select2-container--default').find('.select2-selection__rendered').text($(element).val());
		}, 10);
	});

}

window.addEventListener('turbolinks:load', initializeSelects)