DELETE_CONFIRMATION = {
	init: function() {
		$(".js-show-delete-confirmation").click(function(){
			$(this).hide();
			$('form.delete-confirmation').fadeIn();
			return false
		})
		$(".js-cancel-delete").click(function(){
			$(".actions a").fadeIn();
			$(this).parents('form').hide();
			return false
		});
	}
}

$(document).on('turbolinks:load', DELETE_CONFIRMATION.init);
