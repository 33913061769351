import $ from 'jquery';

function setSupervisor() {
  // Trigger when a location is selected
  $('#compliance_report_location_dimension_id').on('change', function() {
    const id = 1;
    const locationId = $(this).val();
    const supervisorSelect = $('#supervisor-select');

    if (locationId) {
      // Fetch supervisors based on location ID
      $.ajax({
        url: `/compliance_report_approvers/${id}?compliance_report_approver[location_dimension_id]=${locationId}`,
        type: 'GET',
        dataType: 'json',
        success: function(data) {
          // Populate the supervisor dropdown with received data
          $.each(data, function(index, supervisor) {
            supervisorSelect.val(supervisor.id);
          });
          
          // Reinitialize Select2 after updating options (if necessary)
          supervisorSelect.trigger('change.select2');
        },
        error: function() {
          console.error('Failed to fetch supervisors.');
        }
      });
    }
  });
}

// Ensure the function is triggered when Turbolinks loads the page
$(document).on('turbolinks:load', setSupervisor);
