MessageBatches = {
	init: function() {		
	  $(".js-library-message-selector").change(function(){
	  	var messageId = $(this).val()
	  	console.log(messageId);
	  	$.get({ 
	  		url: `/library_messages/${messageId}.json`, 
	  		dataType: 'json'
	  	}).done(function(data) {
	  		$(".js-library-message-body").val(data.body);
	  		// Instead of readonly we could generate extra text fields to capture all 
	  		// the placeholders but this is much quicker today:
	  		if(data.body.search(/{{.+}}/) > 0 || data.subject == 'Other'){
	  			$(".js-library-message-body").removeAttr('readonly')
	  		} else {
	  			$(".js-library-message-body").attr('readonly', 'readonly')
	  		}
	  	});
	  });

	}
	//TODO: possibly a warning if the message is an emergency? To ensure the person knows what they are doing
}

document.addEventListener("turbolinks:load", function() {
  if($(".js-library-message-selector").length > 0) {
    MessageBatches.init();
  }
});