function humanize(str) {
  return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
}

function handleChange() {
		var input = $(this);
		var form = input.parents('form');
		var label = (input.attr('type')==='checkbox') ? input.nextAll('label:first') : input.prevAll('label:first')
		if(label.length==0) { label = input.nextAll('label:first'); }
		if(label.length==0) { label = input; }
		var data;

		// to save just one field:
		if($(this).hasClass("js-auto-save-single")) {
			data = form.find("input[name='_method'], input[name='authenticity_token']").serialize();
			
			if(input.data('save-alternate')){
				var serialization = form.find(input.data('save-alternate')).serialize()
				if(serialization == "") {
					// This is an ugly hack to get a group of checkboxes to work
					if($(this).attr('type')==="checkbox" && $(this).serialize()===""){
						data = data + '&' + $(this).attr('name') + '=0'
					}
				} else {
					data = data + '&' + form.find(input.data('save-alternate')).serialize();	
				}
			} else {
				data = data + '&' + $(this).serialize();
				// checkboxes do not serialize when unchecked:
				if($(this).attr('type')==="checkbox" && $(this).serialize()===""){
					data = data + $(this).attr('name') + '=0'
				}
			}	

			if(input.data('also-save')){
				data = data + '&' + form.find(input.data('also-save')).serialize();
			}

		} else {
			// otherwise the whole form is submitted
			data = form.serialize();
		}

		$("#flash").unbind("ajaxSend").unbind("ajaxComplete");
		// $(this).siblings('.check-mark').remove();
		if(label.children('.auto-save-loading').length==0) {
			label.append('<span class="auto-save-loading"><span class="dots-loader"><span></span><span></span><span></span><span></span></span></span>');
		}
		Application.hideFlash()
		input.trigger('autoSave:ajaxSend')

		$.ajax({
			url: form.attr("action"),
			type: 'POST',
			dataType: 'json',
			data: data,
			success: function(d) {
				label.children('.auto-save-loading').fadeOut(300, function() { $(this).remove() });
				input.removeClass('error').addClass('saved');
				label.removeClass('error');
				$('#flash div').click();
				$("#flash").bind("ajaxSend", Application.default_ajax_send).bind("ajaxComplete", Application.default_ajax_complete);

				input.trigger('autoSave:success', d)
			},
			error: function(d){
				label.children('.auto-save-loading').remove();

				var errors = JSON.parse(d.responseText);
				if(errors.errors)
				{
					errors = errors.errors;
				}
				Application.errors = errors;
				var messages = [];
				for (var field in errors) {
      		messages.push(humanize(field) + " " + errors[field]);
   			}
   			Application.showFlash(messages.join(", "), 'error flash-perm');
				input.addClass('error').focus();
				label.addClass('error');
				$("#flash").bind("ajaxSend", Application.default_ajax_send).bind("ajaxComplete", Application.default_ajax_complete);
				input.trigger('autoSave:error', d)
			}
		});
		return false;
}

$(document).on('change', '.js-auto-save', handleChange)
$(document).on('focus', '.js-auto-save', function(){
	var elem = $(this);
	if(elem.hasClass('saved')){
		elem.removeClass('saved');
	}
});


