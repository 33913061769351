import $ from 'jquery'
import 'select2'

function formatResult (member) {
	if (member.loading) {
		return member.full_name;
	}

	var $container = $(`
		<div class="grid">
			<div class="grid__item medium-up--two-thirds">
				<h5 class="member-name">${member.full_name} - ${member.organization}</h5>
				<div class="member-address">${member.full_address}</div>
			</div>
			<div class="grid__item medium-up--one-third">
				<div class="meta-data">
					<div><svg class="icon avatar-type" width="20" height="20" viewBox="0 -1 28 28"><use xlink:href="#${member.avatar_type}"></use></svg>
					${member.sex}</div>
					<div>DOB: <span class="member-sex">${member.date_of_birth}</span></div>
					<div>Person Number: <span class="person-number">${member.person_number}</span></div>
				</div>
			</div>
		</div>`
	);

	return $container;
}


function formatSelection(member) {
	return member.description || member.full_name;
}

function initializeSelects() {
	$('.js-member-search').each(function(index, elem) {
		var data_scope = $(elem).data('scope');
		var second_data_scope = $(elem).data('second-scope');
		$(elem).select2({
			placeholder: 'Search for Member...',
			ajax: {
				url: '/members',
				dataType: 'json',
				width: 'style',
				delay: 300,
				data: function (params) {
					var query = {
						q: params.term,
						scope: data_scope,
						second_scope: second_data_scope,
						page: params.page || 1,
						per_page: 30
					}

					// Query parameters will be ?q=[term]&page=[page]
					return query;
				},
				processResults: function (data, params) {
					// parse the results into the format expected by Select2
					// since we are using custom formatting functions we do not need to
					// alter the remote JSON data, except to indicate that infinite
					// scrolling can be used
					params.page = params.page || 1;

					return {
						results: data.items,
						pagination: {
							more: (params.page * 30) < data.pagination.count
						}
					};
				},
				cache: true
			},
			minimumInputLength: 2,
			templateResult: formatResult,
			templateSelection: formatSelection
		})
	});

	$(".js-member-name").each(function(index, element){
		setTimeout(function() {
			$(element).siblings('.select2-container--default').find('.select2-selection__rendered').removeAttr('title');
			$(element).siblings('.select2-container--default').find('.select2-selection__rendered').text($(element).val());
		}, 10);
	});
}


window.addEventListener('turbolinks:load', initializeSelects)


$(document).ready(function() {
	var originalOverflowX = 'hidden';
  
	$('html').css('overflow-x', originalOverflowX);  // Restore original overflow-x value
	$('body').css('overflow-x', originalOverflowX);  // Restore original overflow-x value

	$(document).on('focus', '.datepicker', function() {
        $('html').css('overflow-x', '');  // Remove overflow-x property
        $('body').css('overflow-x', '');  // Remove overflow-x property
    });

    $(document).on('blur', '.datepicker', function() {
        $('html').css('overflow-x', originalOverflowX);  // Restore original overflow-x value
        $('body').css('overflow-x', originalOverflowX);  // Restore original overflow-x value
    });

	// Handle Select2 open event
	$(document).on('select2:open', function() {
	  $('html').css('overflow-x', '');  // Remove overflow-x property
	  $('body').css('overflow-x', '');  // Remove overflow-x property
	});

	// Handle Select2 close event
	$(document).on('select2:close', function() {
	  $('html').css('overflow-x', originalOverflowX);  // Restore original overflow-x value
	  $('body').css('overflow-x', originalOverflowX);  // Restore original overflow-x value
	});
});
