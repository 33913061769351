SURVEY_ACKNOWLEDGEMENTS = {
	init: function() {
		$(document).on('click', 'a.js-show-acknowledgement', function(e){
			//TODO: check for command or control key:

			var path = $(this).attr('href')
			if(!path.includes("?")){
				//console.log("Appending filters")
				path = path + "?" + $("#survey_acknowledgement_filters").serialize()
			}
			//console.log(path)
			$.ajax(path, {dataType: "script"})
			return false;
		})
	}
}

$(document).on('turbolinks:load', SURVEY_ACKNOWLEDGEMENTS.init)
