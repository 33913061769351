SORT_RADIOS = {
	init: function() {
		if($('#filters_sort_direction').val() == "ASC") {
			$(".sort-radio:checked").addClass("asc").removeClass("desc");
		} else {
			$(".sort-radio:checked").removeClass("asc").addClass("desc");
		}

		$(".sort-radio").click(function(e) {
			if($('#filters_sort_direction').val() == "ASC") {
				$(this).removeClass("asc").addClass("desc");
				$('#filters_sort_direction').val("DESC");
			} else {
				$(this).addClass("asc").removeClass("desc");
				$('#filters_sort_direction').val("ASC");
			}
			var form = $(this).parents('form')[0];
      global.Rails.fire(form,'submit')
		})

	}
}

document.addEventListener("turbolinks:load", function() {
  if($(".sort-radio").length > 0) {
    SORT_RADIOS.init();
  }

});